"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const menuItmes_1 = require("src/modules/menu/helpers/menuItmes");
const AuthProvider_1 = require("src/providers/AuthProvider");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const TranslationProvider_1 = require("src/providers/TranslationProvider");
const useAsideMenu = () => {
    const { translate, selectedLanguage } = (0, TranslationProvider_1.useTranslation)();
    const [minimize, setMinimize] = (0, react_1.useState)(false);
    const [menuItems, setMenuItems] = (0, react_1.useState)([]);
    const location = (0, react_router_dom_1.useLocation)();
    const [opened, setOpened] = (0, react_1.useState)([]);
    const [menuHeight, setMenuHeight] = (0, react_1.useState)(200);
    const auth = (0, AuthProvider_1.useAuth)();
    const updateWindowDimensions = () => {
        setMenuHeight(window.innerHeight - 120);
    };
    const handleOpenCloseMenu = (el) => {
        if (el.children) {
            setOpened(pre => {
                if (opened.includes(el.url))
                    return pre.filter(item => item !== el.url);
                return [...pre, el.url];
            });
        }
    };
    const minimizeMenu = (minimize) => {
        if (minimize) {
            document.body.classList.add('aside-minimize');
        }
        else {
            document.body.classList.remove('aside-minimize');
        }
        setMinimize(minimize);
    };
    const shouldOpen = (url) => {
        return new RegExp(url === '/' ? '^\\/$' : '^' + url).test(location.pathname);
    };
    const activeNavClass = (url) => {
        return new RegExp(url === '/' ? '^\\/$' : '^' + url).test(location.pathname) ? ' menu-item-active' : '';
    };
    (0, react_1.useEffect)(() => {
        const filterMainMenu = (asideMenuItems, permissions) => {
            const newArray = asideMenuItems.map(el => {
                return {
                    ...el,
                    children: filterMainMenu(el.children, permissions)
                };
            });
            return newArray.filter(item => {
                return item.permission === 'public' || item.children.length > 0 || permissions[item.permission];
            });
        };
        const res = filterMainMenu([...(0, menuItmes_1.rawMenuItems)(translate)], auth.state.data.user?.permissions || {});
        setMenuItems(res);
    }, [auth.state.data.user?.permissions, selectedLanguage]);
    const collectOpenedItemLinks = (menuLinks, newOpenedMenus = []) => {
        menuLinks.forEach(el => {
            if (el.children.length > 0) {
                if (shouldOpen(el.url))
                    newOpenedMenus.push(el.url);
                collectOpenedItemLinks(el.children, newOpenedMenus);
            }
        });
        return newOpenedMenus;
    };
    (0, react_1.useEffect)(() => { setOpened(collectOpenedItemLinks(menuItems)); }, [menuItems, location.pathname]);
    (0, react_1.useEffect)(() => {
        updateWindowDimensions();
        window.addEventListener('resize', updateWindowDimensions);
        return () => { window.removeEventListener('resize', updateWindowDimensions); };
    }, []);
    return { minimize, minimizeMenu, menuItems, activeNavClass, handleOpenCloseMenu, opened, menuHeight };
};
exports.default = useAsideMenu;
